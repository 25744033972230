import React, { Fragment } from "react";
import styles from "./CommonIconCheckbox.module.css";

const CommonCheckboxIcon = ({
  checked,
  img,
  label,
  onSetValue,
  iconprops = true,
  containerStyle = "xl:w-48 lg:w-56 w-40 md:h-12 h-16 cursor-pointer py-2 my-1 px-1",
  topRightCheck = false,
  rightCheck = true,
  containerStyleObj,
  styleIcon = {},
  textStyle = {},
}) => {
  const {
    block_box_all,
    outer_box_unassigned,
    assignedImg,
    assignImg,
    outer_box_assigned,
    block_img_block,
    checkboxDiv,
    assignedImgTop,
  } = styles;
  return (
    <Fragment>
      <div
        className={`${containerStyle} flex relative ${
          iconprops ? block_box_all : block_img_block
        } 
         ${
           checked
             ? `bg-white md:bg-green-100  ${outer_box_assigned}`
             : `bg-white  ${outer_box_unassigned}`
         }`}
        onClick={onSetValue}
        style={containerStyleObj}
      >
        <div className="flex items-center">
          {iconprops && img && (
            <div className={`lg:w-12 w-16 items-center`}>
              <img className="cursor-pointer px-2 " src={img} />
            </div>
          )}

          <div className="w-full items-center">
            <p
              style={textStyle}
              className={`${
                iconprops
                  ? "font-roboto font-normal text-xs py-1 text-black-850"
                  : "font-roboto font-normal text-xs py-1 text-black-850 pl-1 text-left"
              }`}
            >
              {label}
            </p>
          </div>
        </div>
        {rightCheck && !topRightCheck && (
          <div
            className={`${
              checked ? `${assignedImg} ml-auto` : `${assignImg} ml-auto`
            }`}
          ></div>
        )}
        {topRightCheck && !rightCheck && (
          <div
            style={styleIcon}
            className={`${
              checked ? `${checkboxDiv} right-0 top-0 absolute` : `hidden`
            }`}
          >
            <div
              className={`${
                checked ? `${assignedImgTop}` : `${assignImg} ml-auto`
              }`}
            ></div>
          </div>
        )}
      </div>
    </Fragment>
  );
};
export default CommonCheckboxIcon;
