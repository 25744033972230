import React from "react";
import UpdateButton from "../common/components/updateButton/UpdateButton";
import WalkinForm from "../containers/WalkIn/CustomerForm/CustomerFormSteps";
import withErrorHandler from "../hoc/withErrorHandler/withErrorHandler";

const Customer = () => {
  return (
    <UpdateButton message="This application has been updated. It will be reloaded soon.">
      <WalkinForm />
    </UpdateButton>
  );
};

export default withErrorHandler(Customer);
