import React, { useState, useRef, forwardRef } from "react";

const RadioButton = forwardRef((props, ref) => {
  const {
    checked,
    name,
    value,
    onChange,
    onBlur,
    disabled,
    checkColor,
  } = props;

  return (
    <div
      className={`w-5 h-5 rounded-full border  flex justify-center items-center ${
        checkColor
          ? checked
            ? "border-yellow-500"
            : "border-grey-700"
          : checked
          ? "border-green-800"
          : "border-grey-700"
      }`}
    >
      <input
        type="radio"
        ref={ref}
        name={name}
        onChange={onChange}
        checked={checked}
        value={value}
        onBlur={onBlur}
        className="w-6 h-6"
        style={{
          position: "absolute",
          opacity: "0",
          cursor: `${disabled ? "not-allowed" : "pointer"}`,
        }}
        disabled={disabled}
      />
      {
        <div
          className={`w-3  h-3  rounded-full ${
            checkColor
              ? checked
                ? "bg-yellow-500"
                : "bg-grey-700"
              : checked
              ? "bg-green-800"
              : "bg-grey-700"
          }`}
        ></div>
      }
    </div>
  );
});
RadioButton.displayName = "RadioButton";
export default RadioButton;
